import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getFilteredApiRoot, getLoggedInUserRole } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { map, take } from 'rxjs/operators';
import { role, RoleWrapper } from '@libs/shared/models/roles.enum';
import { ApiRootResource } from '@libs/shared/bms-common/api-root/api-root.model';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { getEmbeddedResource, getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { Resource } from '@libs/shared/bms-common/rest/resource.model';
import { isNil } from 'lodash-es';
import { MroRate } from '@libs/shared/models/mro-rate.model';
import { FacilityPage } from '@libs/shared/models/facility.model';

export interface JobOpeningCompanyOptions {
  facilityList: AviationCompany[];
  facilityProfile: AviationCompany;
}

export interface AviationCompany extends Resource {
  location: string;
  name: string;
  shortPresentation: string | null;
  uuid: string;
  checklist: string;
  groupId?: string;
  groupName?: string;
  rates: MroRate[];
  allowPrivateOffers?: boolean;
  allowExperienceLetterRequest?: boolean;
  allowsHideRateInJobOffers?: boolean;
  allowTcnApplicationsToggle?: boolean;
  offerDoubleCheck?: boolean;
  offersHandledCentrally?: boolean;
  requiresAgencyAcceptance?: boolean;
  requiresTechnicianAcceptance?: boolean;
  requiresProductionAcceptance?: boolean;
  requiresQualityAcceptance?: boolean;
  requiresHumanResourcesAcceptance?: boolean;
  requiresAgencySelectionForOfferApplication?: boolean;
  allowsPackageOffers?: boolean;
  allowsPermanentJobOffers?: boolean;
  daysBeforeAutoReject: number;
}

@Injectable()
export class MroFacilityLoaderService {
  private apiRoot: ApiRootResource;
  private wrappedRole: RoleWrapper;

  constructor(private store: Store<any>, private httpService: HttpClient) {
    this.store.pipe(getFilteredApiRoot).subscribe(apiRoot => {
      this.apiRoot = apiRoot;
    });
    this.store.pipe(getLoggedInUserRole, take(1)).subscribe(userRole => {
      this.wrappedRole = role(userRole);
    });
  }

  getMroFacilities(url: string, term: string, pageNumber: number, pageSize: number, additionalParams?: {[key:string]: string}, nameParam: boolean = false): Observable<FacilityPage> {
    let params;
    if (nameParam) {
      params = {
        name: term,
        page: pageNumber,
        size: pageSize,
        sort: 'name,asc',
        ...additionalParams
      };
    } else {
      params = {
        searchTerm: term,
        page: pageNumber,
        size: pageSize,
        sort: 'name,asc',
        ...additionalParams
      };
    }
    const httpParams = new HttpParams({fromObject: params});
    return this.httpService.get<FacilityPage>(`${url.split('?')[0]}`, { params: httpParams });
  }

  public getFacility(facilityUrl: string): Observable<any> {
    return this.httpService.get(facilityUrl);
  }

  public getFacilityWithGroupData(companyUuid: string): Observable<JobOpeningCompanyOptions> {
    if (this.wrappedRole.isHumanResourcesOrCentralQuality()) {
      return this.getFacilityWithGroupDataForCentralUsers(companyUuid);
    } else if (this.wrappedRole.isAdminOrModerator()) {
      return this.getSingleFacilityProfile(getUrl(this.apiRoot, ApiRootLinkRel.GetMroFacilities) + '/' + companyUuid);
    } else if (this.wrappedRole.isMro()) {
      return this.getSingleFacilityProfile(getUrl(this.apiRoot, ApiRootLinkRel.MyFacility));
    }
    throw new Error('Unexpected user type!');
  }

  getFacilityProfile(companyUuid: string): Observable<JobOpeningCompanyOptions> {
    if (this.wrappedRole.isAdminOrModerator()) {
      return this.getSingleFacilityProfile(getUrl(this.apiRoot, ApiRootLinkRel.GetMroFacilities) + '/' + companyUuid);
    } else if(this.wrappedRole.isMro()) {
      return this.getSingleFacilityProfile(getUrl(this.apiRoot, ApiRootLinkRel.MyFacility));
    }
    throw new Error('Unexpected user type!');
  }

  private getFacilityWithGroupDataForCentralUsers(companyUuid: string): Observable<JobOpeningCompanyOptions> {
    return this.httpService.get(getUrl(this.apiRoot, ApiRootLinkRel.GetGroupMroList)).pipe(
      map((response: Resource) => {
        if (isNil(companyUuid)) {
          companyUuid = getEmbeddedResource(this.apiRoot, 'ownMroFacilityUuid');
        }
        const facilityList: any[] = getEmbeddedResource(response, 'facilities');
        const facilityProfile = facilityList.find(facility => facility.uuid == companyUuid);
        return { facilityList: facilityList, facilityProfile: facilityProfile };
      })
    );
  }

  private getSingleFacilityProfile(requestUrl: string): Observable<JobOpeningCompanyOptions> {
    return this.httpService.get(requestUrl).pipe(
      map((facility: AviationCompany) => {
        return { facilityList: [facility], facilityProfile: facility };
      })
    );
  }
}
